.app-features {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  margin-top: 90px;

  .phone {
    width: 400px;
    position: relative;
    min-height: 800px;

    .frame {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%;
    }

    .app-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      margin-top: 10px;
      position: relative;
      top: 0;
      left: 0;

      & > div {
        text-align: center;
        width: 20%;
        margin: 7%;
        font-weight: bold;
        img { max-width: 100%; }
      }
    }

    .app-screens {
      position: absolute;
      top: 60px;
      left: 0;
      margin: 0 20px;
      width: calc( 100% - 40px );
      background: $red;

      img {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        @include transition( all 0.5s ease);

        &.active {
          opacity: 1;
        }
      }
    }
  }
  .left, .right {
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .k {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      padding: 5px 0;
    }
  }

  .callout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0;
    cursor: pointer;
    overflow: visible;
    transform: scale(1);
    height: 125px;
    @include transition( all 0.3s ease);

    &:hover {
      transform: scale(1.05);
    }

    .icon {
      background: $red;
      border-radius: 100px;
      width: 80px;
      height: 80px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      i, svg {
        @include rem-size(2.25);
        color: $white;

        &.fa-file-pen {
          position: relative;
          left: 6px;
        }
      }
    }
    .info {
      width: calc( 100% - 100px );
      margin: 0 10px;
      @include rem-size(0.9);
    }
  }

  .left .callout {
    .icon {
      order: 2;
    }
    .info {
      order: 1;
      margin-right: 20px;
    }
  }
  .right .callout {
    .icon {
      order: 1;
    }
    .info {
      order: 2;
      margin-left: 20px;
    }
  }
}


@media (max-width: 1200px) {
  .app-features{
    .phone {
      width: 350px;
      min-height: 660px;

      .app-screens {
        top: 60px;
        left: 0;
        margin: 0 20px;
        width: calc(100% - 40px);
      }
    }
    .left, .right {
      width: 26%;

      .icon {
        width: 50px;
        height: 50px;

        i, svg {
          @include rem-size(1.5);
          color: $white;

          &.fa-file-pen {
            position: relative;
            left: 6px;
          }
        }
      }
    }
    .callout .info {
      width: calc(100% - 70px);
      
      h5 {
        @include rem-size(1.15);
      }
    }
    .left .callout .info {
      margin-right: 10px;
    }
    .right .callout .info {
      margin-left: 10px;
    }
  }

}

@media (max-width: 991px) {
  .app-features{
    .phone {
      display: none;
    }
    .left, .right {
      width: 48%;

      .icon {
        width: 50px;
        height: 50px;

        i, svg {
          @include rem-size(1.5);
          color: $white;

          &.fa-file-pen {
            position: relative;
            left: 6px;
          }
        }
      }
    }
    .callout .info {
      width: calc(100% - 70px);

      h5 {
        @include rem-size(1.15);
      }
    }
    .left .callout {
      .icon {
        order: 1;
      }
      .info {
        order: 2;
        margin-right: 0;
        margin-left: 10px;
      }
    }
    .left .callout .info {
      margin-right: 10px;
    }
    .right .callout .info {
      margin-left: 10px;
    }
  }
}

@media (max-width: 630px) {
  .app-features{
    display: block;

    .phone {
      display: none;
    }
    .left, .right {
      width: 100%;

      .icon {
        width: 50px;
        height: 50px;

        i, svg {
          @include rem-size(1.5);
          color: $white;

          &.fa-file-pen {
            position: relative;
            left: 6px;
          }
        }
      }
    }
    .callout .info {
      width: calc(100% - 70px);

      h5 {
        @include rem-size(1.15);
      }
    }
    .left .callout {
      .icon {
        order: 1;
      }
      .info {
        order: 2;
        margin-right: 0;
        margin-left: 10px;
      }
    }
    .left .callout .info {
      margin-right: 10px;
    }
    .right .callout .info {
      margin-left: 10px;
    }
  }

}
