footer {
  background-color: $white;
  padding-top: 40px;

  .logo {
    max-height: 50px;
    max-width: 100%;
    margin-top: 20px;
  }

  .copyright {
    background-color: darken($dkblue, 20%);
    text-align: center;
    color: $white;
    padding: 14px;
    margin-top: 60px;
    @include rem-size(0.9);
  }

  .reliable {
    text-align: center;

    b {
      color: $white;
      font-family: "Maven Pro", sans-serif;
      letter-spacing: -0.05em;
      font-weight: 900;
    }

    a {
      color: $yellow;
      @include rem-size(2.5);
      font-family: "Maven Pro", sans-serif;
      letter-spacing: -0.05em;
      font-weight: 700;

      &:hover {
        color: darken($yellow, 10%);
      }
    }

  }
}

@media (max-width: 991px) {
  .footlogo {
    margin-bottom: 24px;
  }
}
@media (max-width: 576px) {
  .col-sm-6 {
    margin-bottom: 24px;
  }
}
