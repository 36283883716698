@mixin doubleRound( $radius: 20px ) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: $radius;
  border-top-left-radius: $radius;
  border-top-right-radius: 0;
}

.background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center 25%;
  background-repeat: no-repeat;
}

.background-contain {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

@mixin flex( $dir:row, $just:space-between, $align:center, $wrap:nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $just;
  align-items: $align;
  flex-wrap: $wrap;
}

@mixin dotransition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin rem-size($remValue: 1 ){
  font-size: ($remValue * $font-size-px-default); //fallback for old browsers
  font-size: $remValue + rem;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin drop-shadow($top: 3px, $left: 0px, $blur: 6px, $color: rgba(0,0,0,0.2), $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $left $top $blur $color;
    -moz-box-shadow:inset $left $top $blur $color;
    box-shadow:inset $left $top $blur $color;
  } @else {
    -webkit-box-shadow: $left $top $blur $color;
    -moz-box-shadow: $left $top $blur $color;
    box-shadow: $left $top $blur $color;
  }
}

@mixin shadow($vert: 3px, $blur: 12px, $opacity: 0.3) {
  -webkit-box-shadow: 0 $vert $blur 0 rgba(0, 0, 0, $opacity);
  -moz-box-shadow: 0 $vert $blur 0 rgba(0, 0, 0, $opacity);
  box-shadow: 0 $vert $blur 0 rgba(0, 0, 0, $opacity);
}

@mixin placeholder($color: #999999) {
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $color;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $color;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $color;
  }
}

@mixin ratio( $ratio: 50% ) {
  //display: block;
  position: relative;
  //width: 100%;
  margin: 0 auto;
  @extend .background-cover;

  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: $ratio;
  }
}

@mixin links( $color: $black, $hover-color: $red, $underline: none, $underline-hover: underline ) {
  a, a:link, a:active, a:visited {
    color: $color;
    text-decoration: $underline;
    @include transition(all .1s ease);
  }
  a:hover {
    color: $hover-color;
    text-decoration: $underline-hover;
  }
}

@mixin columns( $count: 3, $gap: 40px, $rule: 0px, $style: outset ) {
  /* Chrome, Safari, Opera */
  -webkit-column-count: $count;
  -webkit-column-gap: $gap;
  -webkit-column-rule-style: $style;
  -webkit-column-rule-width: $rule;

  /* Firefox */
  -moz-column-count: $count;
  -moz-column-gap: $gap;
  -moz-column-rule-style: $style;
  -moz-column-rule-width: $rule;

  column-count: $count;
  column-gap: $gap;
  column-rule-style: $style;
  column-rule-width: $rule;
}

@mixin article-link(
  $link-spacing: 1,
  $link-border: false,
  $display-category: true,
  $display-photo: true,
  $display-sponsor: true,
  $display-excerpt: true,
  $display-byline: true,
  $display-date: true,
  $photo-width: 30%,
  $photo-ratio: 80%,
  $photo-margin: 12px,
  $headline-rem: 1.25,
  $headline-weight: 400,
  $headline-color: $black,
  $headline-color-hover: $red,
  $excerpt-color: $gray-600,
  $excerpt-rem: 1,
  $sponsor-color: $red,
  $sponsor-rem: 1,
  $byline-color: $gray-600,
  $byline-rem: 1,
  $date-color: $gray-600,
  $date-rem: 1
) {

  display: block;
  margin-top: $link-spacing + rem;
  @include transition(all 0.2s ease);

  @if $link-border {
    padding-bottom: $link-spacing + rem;
    border-bottom: solid 1px $stdBorderColor;
  }

  .category {
    @if $display-category {
      display: block;
    } @else {
      display: none;
    }
    color: $red;
    @extend .cabin;
    @extend .w700;
    // @extend .uppercase;
    @include rem-size(1);
  }

  .photo {
    @if $display-photo {
      display: inline-block;
    } @else {
      display: none;
    }
    width: $photo-width;
    vertical-align: top;
    @extend .background-cover;
    @include ratio($photo-ratio);
    top: 5px;
  }

  .content {
    @if $photo-width == 100% {
      width: 100%;
      display: block;
    }
    @else if $display-photo {
      width: calc(100% - #{$photo-width} - #{$photo-margin});
      margin-left: $photo-margin - 4px;
      display: inline-block;
    }
    @else {
      width: 100%;
      display: block;
    }
  }

  .headline {
    line-height: 1.3em;
    font-weight: $headline-weight;
    color: $headline-color;
    font-family: $font-family-sans-serif;
    padding: 0.2em 0;
    @include rem-size($headline-rem);
    @include transition(all 0.2s ease);
  }

  .excerpt {
    @if $display-excerpt {
      display: block;
    } @else {
      display: none;
    }
    color: $excerpt-color;
    @include rem-size($excerpt-rem);
  }

  .sponsor {
    @if $display-sponsor {
      display: block;
    } @else {
      display: none;
    }
    color: $sponsor-color;
    @include rem-size($sponsor-rem);
  }

  .byline {
    @if $display-byline {
      display: block;
    } @else {
      display: none;
    }
    font-weight: bold;
    color: $byline-color;
    @include rem-size($byline-rem);
  }

  .date {
    @if $display-date {
      display: block;
    } @else {
      display: none;
    }
    color: $date-color;
    @include rem-size($date-rem);
  }

  &:hover {
    text-decoration: none !important;

    .headline {
      color: $headline-color-hover;
    }
  }

}

.square {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  @extend .background-cover;

  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 85%;
  }
}

.rectangle {
  display: block;
  position: relative;
  width: 100%;
  margin: 0 auto;
  @extend .background-cover;

  &-inner {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 55%;
  }
}


// ANIMATIONS

@-webkit-keyframes anim {
  from {-webkit-transform: rotateY(0deg);}
  to {-webkit-transform: rotateY(-360deg);}
}

@keyframes anim {
  from {transform: rotateY(0deg);}
  to {transform: rotateY(-360deg);}
}


// RESETS
.reset-this {
  animation : none;
  animation-delay : 0;
  animation-direction : normal;
  animation-duration : 0;
  animation-fill-mode : none;
  animation-iteration-count : 1;
  animation-name : none;
  animation-play-state : running;
  animation-timing-function : ease;
  backface-visibility : visible;
  background : 0;
  background-attachment : scroll;
  background-clip : border-box;
  background-color : transparent;
  background-image : none;
  background-origin : padding-box;
  background-position : 0 0;
  background-position-x : 0;
  background-position-y : 0;
  background-repeat : repeat;
  background-size : auto auto;
  border : 0;
  border-style : none;
  border-width : medium;
  border-color : inherit;
  border-bottom : 0;
  border-bottom-color : inherit;
  border-bottom-left-radius : 0;
  border-bottom-right-radius : 0;
  border-bottom-style : none;
  border-bottom-width : medium;
  border-collapse : separate;
  border-image : none;
  border-left : 0;
  border-left-color : inherit;
  border-left-style : none;
  border-left-width : medium;
  border-radius : 0;
  border-right : 0;
  border-right-color : inherit;
  border-right-style : none;
  border-right-width : medium;
  border-spacing : 0;
  border-top : 0;
  border-top-color : inherit;
  border-top-left-radius : 0;
  border-top-right-radius : 0;
  border-top-style : none;
  border-top-width : medium;
  bottom : auto;
  box-shadow : none;
  box-sizing : content-box;
  caption-side : top;
  clear : none;
  clip : auto;
  color : inherit;
  columns : auto;
  column-count : auto;
  column-fill : balance;
  column-gap : normal;
  column-rule : medium none currentColor;
  column-rule-color : currentColor;
  column-rule-style : none;
  column-rule-width : none;
  column-span : 1;
  column-width : auto;
  content : normal;
  counter-increment : none;
  counter-reset : none;
  cursor : auto;
  direction : ltr;
  display : inline;
  empty-cells : show;
  float : none;
  font : normal;
  font-family : inherit;
  font-size : medium;
  font-style : normal;
  font-variant : normal;
  font-weight : normal;
  height : auto;
  hyphens : none;
  left : auto;
  letter-spacing : normal;
  line-height : normal;
  list-style : none;
  list-style-image : none;
  list-style-position : outside;
  list-style-type : disc;
  margin : 0;
  margin-bottom : 0;
  margin-left : 0;
  margin-right : 0;
  margin-top : 0;
  max-height : none;
  max-width : none;
  min-height : 0;
  min-width : 0;
  opacity : 1;
  orphans : 0;
  outline : 0;
  outline-color : invert;
  outline-style : none;
  outline-width : medium;
  overflow : visible;
  overflow-x : visible;
  overflow-y : visible;
  padding : 0;
  padding-bottom : 0;
  padding-left : 0;
  padding-right : 0;
  padding-top : 0;
  page-break-after : auto;
  page-break-before : auto;
  page-break-inside : auto;
  perspective : none;
  perspective-origin : 50% 50%;
  position : static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes : '\201C' '\201D' '\2018' '\2019';
  right : auto;
  tab-size : 8;
  table-layout : auto;
  text-align : inherit;
  text-align-last : auto;
  text-decoration : none;
  text-decoration-color : inherit;
  text-decoration-line : none;
  text-decoration-style : solid;
  text-indent : 0;
  text-shadow : none;
  text-transform : none;
  top : auto;
  transform : none;
  transform-style : flat;
  transition : none;
  transition-delay : 0s;
  transition-duration : 0s;
  transition-property : none;
  transition-timing-function : ease;
  unicode-bidi : normal;
  vertical-align : baseline;
  visibility : visible;
  white-space : normal;
  widows : 0;
  width : auto;
  word-spacing : normal;
  z-index : auto;
  /* basic modern patch */
  all: initial;
  all: unset;
}

/* basic modern patch */

#reset-this-root {
  all: initial;
  * {
    all: unset;
  }
}
