nav {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  min-height: $nav-height;
  z-index: 9;
  overflow: visible;
  background: $red;
  @include shadow();

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 11;
    @include transition( all 0.3s ease );

    @media (max-width:690px) {
      text-align: center;
    }

    & > li {
      display: inline-block;
      margin: 0;
      overflow: visible;
      position: relative;

      a {
        display: inline-block;
        height: $nav-height;
        line-height: $nav-height;
        color: $white;
        font-family: "Maven Pro", sans-serif;
        font-weight: 500;
        letter-spacing: -0.05em;
        @include rem-size(1.1);
        text-decoration: none;
        padding: 0 25px;
        @include transition( all 0.3s ease );

        &:last-child {
          margin:0;
        }

        &.active{
          color: $dkblue;
          background: $white;
        }
        &:hover {
          color: $white;
          background: mix($red, $white, 65%);
        }

        @media (max-width:925px) {
          @include rem-size(1.05);
          padding: 0 20px;
        }
        @media (max-width:830px) {
          @include rem-size(0.9);
          padding: 0 10px;
        }

      }

      & > ul {
        position: absolute;
        top: $nav-height;
        left: 0;
        border-top: solid 0px darken($red,10%);
        background: $red;
        height: 0;
        width: 275px;
        @include transition(all 0.5s ease);
        overflow: hidden;

        & > li {
          display: block;
          & > a {
            display: block;
          }

        }
      }

      &:hover > ul {
        height: 187px;
        border-top: solid 2px darken($red,10%);

      }

    }
  }
}

@media (max-width: 705px) {
  nav {
    min-height: 8px;
    top: 85px;

    .container-fluid, .row, .col-12 {
      padding: 0 !important;
      margin: 0 !important;
    }

    ul {
      max-height: 0;
      overflow: hidden;
      &.navOpen {
        max-height: 325px;
      }
      li {
        width: 100%;
        a {
          width: 100%;
          text-align: center;
          @include rem-size(1.1);
          border-bottom: solid 1px mix($red,$white,50%);
        }
        &:last-child a {
          border-bottom: none;
        }
      }
    }
  }
}
