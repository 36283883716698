$splash-height: 750px;

#splash {
  width: 100vw;
  //height: $splash-height;
  padding: 40px 0;
  background-image: url('../../img/bg-splash.jpg');
  @extend .background-cover;


  &-content {
    @include flex();
  }

  h1 {
    //@include rem-size(5);
    font-size: 11cqw;
    color: $dkblue;
    font-weight: 700;
  }
  h2 {
    //@include rem-size(2.75);
    font-size: 6cqw;
    color: $red;
    font-weight: 600;

    b {
      display: inline-block;
      background-color: $red;
      color: $white;
      padding: 0 0.25em;
      border-radius: 0.15em;
      font-weight: 600;
    }
  }

  &-text {
    flex: 1;
    margin-left: 50px;
    container-type: inline-size;
    //background-color: rgba(0,0,0,0.2);

    .list-header {
      @include rem-size(1.4);
      font-weight: bold;
      margin-top: 40px;
    }
    ul {
      margin: 20px 0;
      color: $red;
      list-style: none;

      li {
        color: $black;
        @include rem-size(1.4);
        margin-top: 6px;

        &::before {
          content: "\25B6";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
          color: $red; /* Change the color */
          font-weight: bold; /* If you want it to be bold */
          display: inline-block; /* Needed to add space between the bullet and the text */
          width: 1.25em; /* Also needed for space (tweak if needed) */
          margin-left: -1em;
          @include rem-size(1.25);
        }
      }
    }

    p {
      color: $black;
      @include rem-size(1.4);
      text-align: center;
      font-style: italic;
      font-weight: 700;
    }
  }
}

#splash-title-small { container-type: inline-size; display:none; text-align: center; margin-bottom:20px; }
#splash-title-large { container-type: inline-size; display:block; }

.home-intro-video-text {
  z-index: 1;
  //overflow: hidden;

  h1 {
    background: $red;
    color: $white;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 4px;
  }

  &-container {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-end !important;
    position: relative;
    top: 0;
    left: 0;
    @include transition(all 1.25s ease);

    &.peaceout {
      top: 600px;
    }

    div {
      @include rem-size(1.4);
      padding: 20px 20px;
      width: 100%;
      display: block;
      background: rgba(0,0,0,0.5);
      color: $white;
      border-radius: 4px;
    }

    ul {
      margin: 10px 0 20px;
    }
  }
}

#splash-phone {
  height: $splash-height - 80px;
  width: ($splash-height - 80px) / 2.016129;
  position: relative;
  @include transition(all 1.25s ease);
  left: 0;
  top: 0;

  img.phoneframe {
    position: absolute;
    top: 0;
    left: 0;
    height: $splash-height - 80px;
    width: auto;
  }
  video {
    position: absolute;
    top: 16px;
    left: 18px;
    height: $splash-height - 112px;
    width: auto;
  }

  &.peaceout {
    left: -1000px;
  }
}

@media (max-width: 991px) {
  #splash {
    &-phone {
      height: 500px;
      width: 250px;
      img.phoneframe {
        height: 500px;
      }
      video {
        position: absolute;
        top: 11px;
        left: 14px;
        height: 480px;
        width: auto;
      }
    }
    &-text {
      margin-left: 35px;
      ul li {
        @include rem-size(1.1);
        &::before {
          @include rem-size(1.1);
        }
      }
      p, .list-header { @include rem-size(1.1); }
    }
  }
}

@media (max-width: 767px) {
  #splash {
    padding: 20px 0;
    &-phone {
      height: 460px;
      width: 225px;
      img.phoneframe {
        height: 460px;
      }
      video {
        position: absolute;
        top: 10px;
        left: 12px;
        height: 440px;
        width: auto;
      }
    }
    &-text {
      margin-left: 15px;
      ul li {
        @include rem-size(1.1);
        &::before {
          width: 1.25em; /* Also needed for space (tweak if needed) */
          margin-left: -1.5em;
          @include rem-size(0.9);
        }
      }
      p, .list-header { @include rem-size(1.1); }
    }
  }
  #splash-title-small { display:block; }
  #splash-title-large { display:none; }
}

@media (max-width: 550px) {
  #splash {
    padding: 20px 0;
    &-phone {
      height: 550px;
      width: 273px;
      margin: auto;

      img.phoneframe {
        height: 550px;
      }
      video {
        position: absolute;
        top: 11px;
        left: 15px;
        height: 526px;
        width: auto;
      }
    }
    &-content {
      display: block !important;
      text-align: center;
    }
    &-text {
      margin-left: 0;
      ul li {
        @include rem-size(1.1);
        &::before {
          width: 1.25em; /* Also needed for space (tweak if needed) */
          margin-left: -1.5em;
          @include rem-size(0.9);
        }
      }
      p, .list-header { @include rem-size(1.1); }
    }
  }
  #splash-title-small { display:block; }
  #splash-title-large { display:none; }
}







div.home-intro {
  background: #666666; //transparent url("../../img/home-static.jpg") center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}


.phonevid {
 // background: ;
}

#splash-newhome {
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

#splash-sub {
  width: 100%;
  height: 550px;


  &.small {
    height: 300px;
  }

  &.home {
    //background: transparent url("../../img/istockphoto-1151392524-2048x2048-2.jpg") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.switch {
    position: relative;
    overflow: hidden;

    video {
      position: absolute;
      top: 0;
      left: 0;

      &.fit-width {
        width: 100%;
      }
      &.fit-height {
        height: 100%;
      }
    }

  }
  &.buynow {
    background: transparent url("../../img/exterior-flashing-lights.gif") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.knowtifi {
    background: transparent url("../../img/img-stalker.jpg") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.about {
    background: transparent url("../../img/sub-moms-out.jpg") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.contact {
    background: transparent url("../../img/sub-grandpa.jpg") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.news {
    background: transparent url("../../img/sub-holding-hands.jpg") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.article {
    background: transparent center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

  }
  .article-blur {
    backdrop-filter: blur(10px);
  }
  &.about911 {
    //background: transparent url("../../img/istockphoto-1320888842-2048x2048.jpg") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.versus {
    //background: transparent url("../../img/istockphoto-1139659637-2048x2048.jpg") center bottom no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.faqs {
    background: transparent url("../../img/sub-family.jpg") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  &.versus {
    background: transparent url("../../img/sub-cyber.png") center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .splash-sub-content {
    position: relative;
    height: 550px;
    .splash-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 550px;
    }
  }
  .col-12 {
    text-align: center;

    .splash-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 300px;
    }
    h2 {
      transform: scale(0.8);
    }
  }

  strong {
    display: block;
    @include rem-size(1.75);
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.75);
    color: $yellow;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
  }
  h1 {
    @include rem-size(4);
    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.75);
    color: $white;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-align: center;

    @media (max-width:529px) {
      @include rem-size(3.5);
    }
    @media (max-width:480px) {
      @include rem-size(3);
    }
    @media (max-width:425px) {
      @include rem-size(2.6);
    }
  }
  h2 {
    @include rem-size(4);
    color: $white;

    @media (max-width:529px) {
      @include rem-size(3.5);
    }
    @media (max-width:480px) {
      @include rem-size(3);
    }
    @media (max-width:425px) {
      @include rem-size(2.6);
    }
  }
  .up {
    background: rgba(0,0,0,0.6);
    color: $white;
    padding: 30px;

    @media (max-width: 530px) {
      @include rem-size(1);
    }
  }

}
