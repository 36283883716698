#contact-form {
  .form-group {
    margin-bottom: 16px;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8F9CAF;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #8F9CAF;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #8F9CAF;
  }
}

.form-wrap {
  background-color: $dkblue;
  padding: 30px;
  border-radius: 6px;

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $white;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color:$white;
  }
  .btn {
    color: $white;

    &:hover {
      background-color: $red;
      i {
        color: $white;
      }
    }

    i {
      color: $red;
      @include transition(all 0.3s ease);
    }
  }
  .form-control {
    border-width: 0 0 1px;
    border-radius: 0;
    background-color: $dkblue;
    color: $white;
  }
  .input-group-text {
    border-width: 0 0 1px;
    background-color: $dkblue;
    color: $red;
    border-radius: 0;
    padding: 0.375rem 5px;
  }

  .contact-error {
    background-color: $red;
    color: $white;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 12px;
  }
}

