
.hows-it-work {
  @include rem-size(3.25);
  text-align: center;
  margin-top: 50px;
  padding-top: 50px;
  border-top: solid 1px $gray-300;
  font-weight: 700;
  margin-bottom: 25px;

  &-sub {
    @include rem-size(1.1);
    margin-bottom: 40px;
    text-align: center;
  }
  &-item {
    padding-bottom: 40px;

    img {
      border-radius: 100px;
      margin-bottom: 30px;
      max-width: 160px;
    }
    
    .icon {
      width: 160px;
      height: 160px;
      margin: auto;
      border-radius: 100px;
      @include flex(row, center, center);
      background: radial-gradient(circle at center, $red, $red 15%, darken($red,20%) 100%);
      margin-bottom: 30px;

      i {
        color: $white;
        font-size: 70px;
      }

    }
  }
}

.top-border {
  margin-top: 50px;
  padding-top: 50px;
  border-top: solid 1px $gray-300;
}
